import { FC, useCallback, useState, useMemo } from 'react'
import { useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import EmailEditor from '~/components/SendMailFormControl/EmailEditor'
import { Button } from '~/core/ui/Button'
import { Dialog } from '~/core/ui/Dialog'
import { DynamicImportForm } from '~/core/ui/DynamicImportForm'
import { IFormAction } from '~/core/ui/Form'
import { IAttachmentsFiles, IAttachmentsFile } from '~/core/ui/RichEditor'
import { useQueryEmailTemplateSendToClient } from '~/lib/features/candidates/hooks/use-query-template-send-to-client'
import schemaSendToClient from '~/lib/features/candidates/schema/validation-send-to-client'
import { ISendToClientForm } from '~/lib/features/candidates/types'
import useUploadS3AWS, {
  ACCEPT_FILES_COMMON,
  LIST_ACCEPT_FILES_COMMON,
  MAXIMUM_10_MB
} from '~/lib/hooks/use-upload-s3-aws'
import useBoundStore from '~/lib/store'
import FilesModal from '~/components/List/FilesModal'
import { RESUME, LOCAL } from '~/core/constants/enum'
import { sumFilesSize } from '~/core/ui/utils'

const SendToClientModal: FC<{
  open: boolean
  onOpenChange: (opened: boolean) => void
  defaultValue?: ISendToClientForm
  disableCTA?: boolean
  emailEditorPlaceholderValue?: { [key: string]: string }
  onSubmit?: (data: ISendToClientForm, formAction: IFormAction) => Promise<any>
}> = ({
  open,
  onOpenChange,
  disableCTA = false,
  defaultValue,
  emailEditorPlaceholderValue = {},
  onSubmit
}) => {
  const { t } = useTranslation()
  const { setToast } = useBoundStore()
  const { files, setFiles } = useUploadS3AWS({
    defaultFiles: defaultValue?.files || []
  })

  const [openFilesModal, setOpenFilesModal] = useState<boolean>(false)

  const { promiseEmailTemplatesSendToClient } =
    useQueryEmailTemplateSendToClient()

  const onFinish = useCallback(
    (data: ISendToClientForm, formAction: IFormAction) => {
      const formatData = {
        ...data,
        files
      }
      return onSubmit ? onSubmit(formatData, formAction) : Promise.resolve()
    },
    [files]
  )

  const excludeFilesLocal = useMemo(
    () => files.filter((file) => file.type !== LOCAL),
    [files]
  )

  const onAttachFiles = (data: IAttachmentsFile[]) => {
    const filesLocal = files.filter((file) => file.type === LOCAL)
    const newList = [...data, ...filesLocal]
    const totalSavedFilesSize = sumFilesSize(newList)
    if (totalSavedFilesSize > MAXIMUM_10_MB) {
      setToast({
        open: true,
        type: 'error',
        title: `${t('notification:attachmentFailed')}`,
        description: `${t('notification:attachmentFailedDescription01', {
          number: '10MB'
        })}`
      })
    } else {
      setFiles([...newList])
      setOpenFilesModal(false)
    }
  }

  return (
    <Dialog
      size="md"
      open={open}
      onOpenChange={onOpenChange}
      isDivider={false}
      isPreventAutoFocusDialog={true}
      className="tablet:w-[672px] tablet:max-w-[672px]"
      label={`${t('candidates:sendToClient:title')}`}>
      <DynamicImportForm
        isShowDebug={false}
        className="w-full"
        schema={schemaSendToClient(t)}
        defaultValue={defaultValue}
        onSubmit={onFinish}>
        {({ control, setValue, setError, formState, clearErrors }) => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          const toWatcher = useWatch({
            control,
            name: 'to'
          })
          return (
            <>
              <EmailEditor
                label=""
                control={control}
                setValue={setValue}
                setError={setError}
                formState={formState}
                clearErrors={clearErrors}
                showFields={['to', 'subject', 'cc', 'bcc', 'htmlBody']}
                defaultTemplateEmail={undefined}
                placeholderValue={{
                  ...emailEditorPlaceholderValue,
                  ...((toWatcher || []).length > 0
                    ? {
                        contact_values: (toWatcher || [])
                          .map((contact) =>
                            contact.__isNew__
                              ? contact.label
                              : contact?.supportingObj?.name
                          )
                          .join(', ')
                      }
                    : {})
                }}
                isShowComposeEmail={true}
                promiseEmailTemplates={promiseEmailTemplatesSendToClient}
                onSubmitUpdateEmailProfile={() => Promise.resolve()}
                isLoadingUpdateEmailProfile={false}
                fieldsName={{
                  emailTemplate: 'emailTemplate',
                  htmlBody: 'htmlBody',
                  subject: 'subject',
                  to: 'to',
                  cc: 'cc',
                  bcc: 'bcc'
                }}
                extraToolbar={{
                  attachmentsList: [
                    {
                      type: 'fromLocal',
                      label: `${t('label:choose_from_computer')}`,
                      show: true,
                      showPosition: 'menuToolbar',
                      acceptedFiles: ACCEPT_FILES_COMMON,
                      classNameItem:
                        'w-[calc(35vw_-_90px)] tablet:w-[calc(35vw_-_110px)]',
                      fileChange: (fileList) => {
                        let totalSavedFilesSize = 0
                        for (let i = 0; i < (fileList || [])?.length; i++) {
                          totalSavedFilesSize += (fileList || [])[i].size
                        }
                        const totalFileSize =
                          totalSavedFilesSize +
                          files.reduce(
                            (totalSize, file) =>
                              totalSize + (file.file?.size || 0),
                            0
                          )
                        if (totalFileSize <= MAXIMUM_10_MB) {
                          let arr: IAttachmentsFiles = []
                          Array.from(fileList || []).forEach((file) => {
                            arr.push({
                              id: undefined,
                              url: '',
                              name: file.name,
                              status: !LIST_ACCEPT_FILES_COMMON.includes(
                                file.type
                              )
                                ? 'error'
                                : 'pending',
                              statusDescription:
                                !LIST_ACCEPT_FILES_COMMON.includes(file.type)
                                  ? `${t('form:invalid_upload_file_email')}`
                                  : '',
                              type: LOCAL,
                              file: file
                            })
                          })
                          setFiles([...files, ...arr])
                        } else {
                          setToast({
                            open: true,
                            type: 'error',
                            title: `${t('notification:attachmentFailed')}`,
                            description: `${t(
                              'notification:attachmentFailedDescription01',
                              {
                                number: '10MB'
                              }
                            )}`
                          })
                        }
                      },
                      itemPerRow: 'calc(50% - 8px)',
                      list: files,
                      onDelete: ({ index }) => {
                        setFiles((files || [])?.filter((_, i) => i !== index))
                      }
                    },
                    {
                      type: 'fromFiles',
                      label: `${t('label:choos_from_files')}`,
                      show: true,
                      showPosition: 'menuToolbar',
                      acceptedFiles: ACCEPT_FILES_COMMON,
                      itemPerRow: 'calc(50% - 8px)',
                      list: [],
                      onClick: () => setOpenFilesModal(true)
                    }
                  ]
                }}
                optionsToSearch={defaultValue?.optionsToSearch}
              />
              <div className="mt-6 flex justify-end space-x-3">
                <Button
                  type="secondary"
                  size="sm"
                  label={`${t('button:cancel')}`}
                  onClick={() => onOpenChange(false)}
                  isDisabled={disableCTA}
                  isLoading={disableCTA}
                />
                <Button
                  label={`${t('button:sendEmail')}`}
                  size="sm"
                  htmlType={
                    !!files.find((file) => file.status === 'error')
                      ? 'button'
                      : 'submit'
                  }
                  isDisabled={disableCTA}
                  isLoading={disableCTA}
                />
              </div>
            </>
          )
        }}
      </DynamicImportForm>
      {openFilesModal && (
        <FilesModal
          open={openFilesModal}
          onOpenChange={() => setOpenFilesModal(false)}
          profileId={defaultValue?.profileId}
          fileResume={
            defaultValue?.files?.filter((item) => item.type === RESUME)[0]
          }
          filesSelected={excludeFilesLocal}
          onAttachFiles={onAttachFiles}
        />
      )}
    </Dialog>
  )
}

export default SendToClientModal
