import { formatISO } from 'date-fns'
import { ComponentProps, FC, ReactPropTypes, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'urql'
import { Button } from '~/core/ui/Button'
import { Dialog } from '~/core/ui/Dialog'
import { Dot } from '~/core/ui/Dot'
import IconWrapper from '~/core/ui/IconWrapper'
import { Tooltip } from '~/core/ui/Tooltip'
import createModalHook from '~/lib/hooks/create-modal-hooks'
import useBoundStore from '~/lib/store'
import { IPlacement } from '../placement'
import { ICreateEditPlacement } from '../schema'
import { createPlacementMutation } from './graphql/mutation-create-placement'
import PlacementForm from './PlacementForm'
import ToolTipOnOverflow from './ToolTipOnOverflow'

const CreatePlacementDialog: FC<{
  open: boolean
  setOpen: (open: boolean) => void
  onSubmit?: (data: ICreateEditPlacement) => Promise<IPlacement>
  openParams?: {
    onPlacementCreated: (placement: IPlacement) => void
    header?: { candidateName?: string; jobTitle?: string; companyName?: string }
    applicant: ComponentProps<typeof PlacementForm>['applicant']
    defaultValues?: { applicationId?: number; jobStageId?: number }
  }
}> = ({ open, setOpen, onSubmit, openParams }) => {
  const { t } = useTranslation()
  const user = useBoundStore((state) => state.user)
  const defaultValue = useMemo<ICreateEditPlacement>(
    () => ({
      //@ts-ignore
      applicantId: openParams?.defaultValues?.applicationId?.toString(),
      //@ts-ignore
      jobStageId: openParams?.defaultValues?.jobStageId?.toString(),
      typeOfSalary: 'annually',
      typeOfFee: 'percentage',
      currencyOfRevenue: user?.currentTenant?.currency,
      profitSplits: [
        {
          user_id: {
            value: user.id?.toString() as string,
            avatar: user.avatar,
            defaultColour: user.defaultColour,
            avatarVariants: user.avatarVariants,
            supportingObj: {
              name: user.fullName,
              defaultColour: user.defaultColour
            }
          },
          profit_percentage: '100'
        }
      ]
    }),
    [openParams?.defaultValues, user]
  )
  return (
    <Dialog
      open={open}
      onOpenChange={setOpen}
      isDivider={true}
      isPreventAutoFocusDialog={true}
      size="sm"
      label={`${t('placements:createPlacement')}`}
      description={
        <div className="mb-5">
          <div className="flex items-center">
            <div className="mr-2 shrink-0">
              <IconWrapper size={16} name="User" />
            </div>
            <Tooltip content={openParams?.header?.candidateName}>
              <div className="line-clamp-1 text-sm text-gray-900">
                {openParams?.header?.candidateName}
              </div>
            </Tooltip>
          </div>
          <div className="mt-1.5 flex items-center">
            <div className="mr-2 shrink-0">
              <IconWrapper size={16} name="Briefcase" />
            </div>
            <ToolTipOnOverflow
              text={openParams?.header?.jobTitle}
              className="max-w-[200px] shrink-0 truncate text-sm text-gray-900"
            />
            <div className="ml-2 mr-2 h-0.5 w-0.5 shrink-0  rounded-full bg-gray-400"></div>
            <ToolTipOnOverflow
              className="shrink-1 truncate"
              text={openParams?.header?.companyName}
            />
          </div>
        </div>
      }
      headingClassName="tablet:pb-0">
      {openParams?.defaultValues && (
        <PlacementForm
          applicant={openParams?.applicant}
          hiddeFields={['status']}
          onSubmit={(data) => {
            return onSubmit
              ? onSubmit(data).then((placement) => {
                  setOpen(false)
                  openParams?.onPlacementCreated(placement)
                })
              : Promise.reject('Handler not provided')
          }}
          defaultValue={defaultValue}>
          <div className="mt-6 flex items-center justify-end space-x-3">
            <Button
              label={`${t('button:cancel')}`}
              size="sm"
              type="secondary"
              onClick={() => setOpen(false)}
            />
            <Button
              size="sm"
              label={`${t('interview:email_modal:save')}`}
              htmlType="submit"
            />
          </div>
        </PlacementForm>
      )}
    </Dialog>
  )
}

export const useCreatePlacementModel = () => {
  const [{ fetching: creatingRequisition }, createPlacement] = useMutation(
    createPlacementMutation
  )
  const {
    ModalComponent: PlacementModalComponent,
    openModal: openPlacementModel
  } = createModalHook(CreatePlacementDialog)((data) => {
    return createPlacement({
      applicantId: +data.applicantId,
      jobStageId: +data.jobStageId,
      hiredDate: formatISO(data.hiredDate),
      onboardDate: data.onboardDate ? formatISO(data.onboardDate) : undefined,
      endOfProbationDate: data.endOfProbationDate
        ? formatISO(data.endOfProbationDate)
        : undefined,
      salary: data.salary ? +data.salary : undefined,
      typeOfSalary: data.typeOfSalary,
      currencyOfSalary: 'USD',
      fee: data.fee ? +data.fee : undefined,
      typeOfFee: data.typeOfFee,
      revenue: data.revenue ? +data.revenue : undefined,
      currencyOfRevenue: data.currencyOfRevenue
        ? data.currencyOfRevenue
        : undefined,
      profitSplits: data.profitSplits.map((item) => ({
        userId: +item.user_id?.value,
        profitPercentage: +item.profit_percentage
      }))
    }).then((rs) => {
      if (rs.error) {
        throw rs.error
      }
      return rs.data?.placementsCreate?.placement
    })
  })

  return {
    PlacementModalComponent,
    openPlacementModel
  }
}

export default CreatePlacementDialog
