import { gql } from 'urql'

const QueryProfileAttachmentsList = gql`
  query ($limit: Int, $page: Int, $search: String, $profileId: Int) {
    profileAttachmentsList(
      limit: $limit
      page: $page
      search: $search
      profileId: $profileId
    ) {
      collection {
        id
        tenantId
        name
        key
        file
        recordType
        recordId
        blobs
        deletedAt
        createdAt
        updatedAt
        sharedUserIds
        attachmentOwner
      }
      metadata {
        totalCount
      }
    }
  }
`

export default QueryProfileAttachmentsList
