import { gql } from 'urql'

const QueryEmailTemplatesSendToClient = gql<
  {
    stcEmailTemplate: {
      name: string
      body: string
      subject: string
    }
  },
  {}
>`
  query {
    stcEmailTemplate
  }
`

export default QueryEmailTemplatesSendToClient
