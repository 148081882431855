import { differenceInCalendarYears } from 'date-fns'
import { ComponentProps, useCallback, useEffect, useRef, useState } from 'react'
import { DateRange } from 'react-day-picker'
import { useTranslation } from 'react-i18next'
import { CloseCircleFill } from '~/core/ui/FillIcons'
import IconWrapper from '~/core/ui/IconWrapper'
import {
  Popover,
  PopoverContent,
  PopoverPortal,
  PopoverTrigger
} from '~/core/ui/Popover'
import {
  RangeDatePicker,
  RangeDatePickerSizeProps
} from '~/core/ui/RangeDatePicker'
import { cn } from '~/core/ui/utils'
import debounce from '~/core/utilities/debounce'
import {
  defaultFormatDate,
  monthDayFormatDate
} from '~/core/utilities/format-date'
import { createFilterControl, FilterControlItemType } from '.'

export const FilterItemRangeDatePicker = createFilterControl(
  (
    props: ComponentProps<typeof RangeDatePicker> &
      FilterControlItemType & {
        onFilterSubmit?: undefined
        size?: RangeDatePickerSizeProps
        value?: DateRange
        onChange?: (
          value: DateRange | undefined,
          options?: { triggerFilterChange: boolean }
        ) => void
        placeholder?: string
        menuAlign?: 'top' | 'right' | 'bottom' | 'left'
        menuSide?: 'center' | 'start' | 'end'
        autoUpdateValue?: boolean
        triggerFilterChange?: boolean
      }
  ) => {
    const { triggerFilterChange = true } = props
    const { i18n } = useTranslation()
    const onFilterSubmitRef = useRef<Function>()
    onFilterSubmitRef.current = props.onFilterSubmit
    const sizeIcon = props.size === 'sm' ? 12 : 14

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const callSubmit = useCallback(
      debounce(() => {
        onFilterSubmitRef.current && onFilterSubmitRef.current()
      }, 500),
      [onFilterSubmitRef?.current]
    )

    const handleChange = useCallback(
      (value?: DateRange) => {
        if (props.onChange) {
          props.onChange(value, { triggerFilterChange })
        }
        if (value && value.from && value.to) {
          triggerFilterChange && callSubmit()
        }
      },
      [props, triggerFilterChange, callSubmit]
    )

    const formatDates = () => {
      const isDifferentYear =
        props.value?.to && props.value?.from
          ? differenceInCalendarYears(
              new Date(props.value.to),
              new Date(props.value.from)
            )
          : 0

      return `${
        props.value?.from
          ? isDifferentYear > 0
            ? defaultFormatDate(new Date(props.value.from))
            : monthDayFormatDate(new Date(props.value.from))
          : 'From'
      } - ${
        props.value?.to
          ? isDifferentYear > 0
            ? defaultFormatDate(new Date(props.value.to))
            : monthDayFormatDate(new Date(props.value.to))
          : 'to'
      }`
    }

    /**
     * Revert the value to the previous value when the popover is closed and the value is invalid
     */
    const [open, setOpenChange] = useState(false)
    const validValueRef = useRef(props.value)
    if (
      !props.value ||
      (props.value?.from && props.value.to) ||
      (!props.value?.from && !props.value.to)
    ) {
      validValueRef.current = props.value
    }
    useEffect(() => {
      if (!open && props.value?.from && !props.value.to) {
        props.onChange &&
          props.onChange(validValueRef.current, { triggerFilterChange })
      }
    }, [open, props.value, triggerFilterChange])

    return (
      <Popover open={open} onOpenChange={setOpenChange}>
        <PopoverTrigger asChild>
          <div
            className={cn(
              'group flex min-w-[95px] cursor-pointer items-center justify-center space-x-1.5 rounded border-[1px] border-solid border-gray-300 bg-white text-gray-600 hover:bg-gray-50 focus:ring-2 focus:ring-primary-300 focus:ring-offset-2',
              props.size === 'sm' ? 'h-6 py-[3px] pl-1.5 pr-2' : '',
              props.size === 'md' ? 'h-8 py-[3px] pl-3 pr-2.5' : ''
            )}>
            <span
              className={cn(
                'flex-1',
                props.value ? 'text-gray-900' : 'text-gray-600',
                props.size === 'sm' ? 'text-xs font-medium' : '',
                props.size === 'md' ? 'text-sm font-normal' : ''
              )}>
              {props.value ? formatDates() : props.placeholder}
            </span>
            <div
              onClick={(e) => {
                e.stopPropagation()
                if (props.value && props.onChange) {
                  props.onChange(undefined, { triggerFilterChange })
                }
                triggerFilterChange && callSubmit()
              }}>
              {props.value?.from && props.value?.to ? (
                <>
                  <div className="hidden group-hover:block">
                    <CloseCircleFill size={sizeIcon} />
                  </div>
                  <div className="block group-hover:hidden">
                    <IconWrapper name="ChevronDown" size={sizeIcon} />
                  </div>
                </>
              ) : (
                <IconWrapper name="ChevronDown" size={sizeIcon} />
              )}
            </div>
          </div>
        </PopoverTrigger>

        <PopoverPortal>
          <PopoverContent
            className="my-ignore-action"
            side={props?.menuAlign || 'top'}
            align={props?.menuSide || 'end'}>
            <RangeDatePicker
              locale={i18n.language}
              useOnlyFunction
              {...props}
              config={{
                value: props.value,
                onChange: handleChange,
                ...(props.config || {})
              }}
            />
          </PopoverContent>
        </PopoverPortal>
      </Popover>
    )
  }
)
