import { FC, useRef, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '~/core/ui/Button'
import { Dialog } from '~/core/ui/Dialog'
import { Container } from '~/core/ui/Container'
import { ScrollArea } from '~/core/ui/ScrollArea'
import { IAttachmentsFile } from '~/core/ui/RichEditor'
import { ISource, IPagination } from '~/lib/features/candidates/types'
import FilesGroup from './FilesGroup'
import { Controller, useForm } from 'react-hook-form'
import { IParamsTableInfinity } from '~/core/@types/global'
import { IAttachmentsFileProfile } from '~/lib/features/settings/profiles/types'
import QueryProfileAttachmentsList from '~/lib/features/settings/profiles/graphql/profiles-attachmentsList'
import useContextGraphQL, {
  IResponseContextResult
} from '~/core/middleware/use-context-graphQL'
import configuration from '~/configuration'
import { catchErrorFromGraphQL } from '~/core/utilities/catch-api-error'
import useBoundStore from '~/lib/store'
import { CLOUD, RESUME } from '~/core/constants/enum'

const FilesModal: FC<{
  open: boolean
  onOpenChange: (opened: boolean) => void
  fileResume?: IAttachmentsFile
  profileId?: number
  onAttachFiles: (data: IAttachmentsFile[]) => void
  filesSelected?: IAttachmentsFile[]
}> = ({
  open,
  onOpenChange,
  fileResume,
  profileId,
  onAttachFiles,
  filesSelected
}) => {
  const { t } = useTranslation()
  const { control } = useForm()
  const scrollRef = useRef<HTMLDivElement>(null)

  const [source, setSource] = useState<ISource[]>([])
  const [files, setFiles] = useState<Array<IAttachmentsFile>>([])
  const [resumes, setResumes] = useState<IAttachmentsFile[]>([])
  const [isFetching, setIsFetching] = useState<boolean>(false)
  const { clientGraphQL } = useContextGraphQL()
  const { setToast } = useBoundStore()

  const fetchData = async (
    pageParam = {} as IParamsTableInfinity
  ): Promise<IPagination> => {
    setIsFetching(true)
    return clientGraphQL
      .query(QueryProfileAttachmentsList, {
        ...pageParam,
        limit: configuration.defaultPageSize,
        search: pageParam.search || '',
        profileId: Number(profileId)
      })
      .toPromise()
      .then((result: IResponseContextResult<IAttachmentsFileProfile>) => {
        if (result.error) {
          catchErrorFromGraphQL({
            error: result.error,
            page: configuration.path.settings.members,
            setToast
          })

          return {
            data: [],
            meta: {
              totalRowCount: 0,
              pageSize: configuration.defaultPageSize
            }
          }
        }
        const { profileAttachmentsList } = result.data
        const collection = profileAttachmentsList?.collection || []
        const metadata = profileAttachmentsList?.metadata || {}
        const convert = collection.map((item) => {
          return {
            id: item.id,
            name: item.blobs?.filename,
            url: item.file,
            size: item?.blobs?.size || 0,
            type: CLOUD
          }
        })
        setIsFetching(false)
        return {
          data: convert,
          meta: {
            totalRowCount: metadata.totalCount,
            pageSize: configuration.defaultPageSize
          }
        }
      })
  }

  const onSubmit = () => {
    onAttachFiles([
      ...resumes.filter((item) => item.selected),
      ...files.filter((item) => item.selected)
    ])
  }

  const onSelectedFile = (val: ISource) => {
    if (val?.type === CLOUD) {
      setFiles(val?.attachments || [])
    } else if (val?.type === RESUME) {
      setResumes(val?.attachments || [])
    }
  }

  useEffect(() => {
    setSource([
      {
        label: t('label:cv_resume') || '',
        attachments: fileResume ? [{ ...fileResume }] : [],
        onGetData: (): Promise<IPagination> => {
          return Promise.resolve({
            data: fileResume ? [{ ...fileResume }] : [],
            meta: {
              totalRowCount: 1
            }
          })
        },
        type: RESUME
      },
      {
        label: t('label:files') || '',
        onGetData: fetchData,
        loading: isFetching,
        type: CLOUD
      }
    ])
  }, [open, t])

  return (
    <Dialog
      size="md"
      open={open}
      onOpenChange={onOpenChange}
      isDivider={false}
      isPreventAutoFocusDialog={true}
      className="tablet:w-[555px] tablet:max-w-[555px]"
      label={`${t('candidates:tabs:files')}`}>
      <div className="flex flex-col space-x-3">
        <ScrollArea
          scrollRef={scrollRef}
          className={'-mt-1 flex max-h-[476px] flex-col'}>
          <Container>
            {source?.map((item, index) => {
              return (
                <Controller
                  key={index}
                  control={control}
                  name="source"
                  defaultValue={item}
                  render={() => (
                    <FilesGroup
                      data={item}
                      onSelectedFile={onSelectedFile}
                      filesSelected={filesSelected}
                    />
                  )}
                />
              )
            })}
          </Container>
        </ScrollArea>
        <div className="mt-6 flex justify-end space-x-3">
          <Button
            type="secondary"
            size="sm"
            label={`${t('button:cancel')}`}
            onClick={() => onOpenChange(false)}
          />
          <Button
            label={`${t('button:attach')}`}
            size="sm"
            onClick={onSubmit}
          />
        </div>
      </div>
    </Dialog>
  )
}

export default FilesModal
