import React from 'react'
import { Skeleton } from '~/core/ui/Skeleton'

function FileManagementLoadingView() {
  return (
    <div className="mt-4 grid gap-y-2">
      {Array.from(Array(5).keys()).map((item) => (
        <div
          key={`task-skeleton-${item}`}
          className="flex justify-between gap-x-3 rounded-md border border-gray-100 px-3 py-2.5">
          <div className="h-full w-2/3">
            <Skeleton className="h-5 w-full rounded" />
          </div>
          <div className="flex flex-row space-x-2">
            <Skeleton className="h-5 w-20 rounded" />
            <Skeleton className="h-5 w-5 rounded" />
            <Skeleton className="h-5 w-5 rounded" />
          </div>
        </div>
      ))}
    </div>
  )
}

export default FileManagementLoadingView
