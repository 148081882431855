const MutationSendEmailToApplicantClient = `
  mutation ($id: Int!, $subject: String!, $tos: [String!]!, $cc: [String!], $bcc: [String!], $htmlBody: String!, $attachments: [File!], $attachmentIds: [Int!]) {
    applicantsSendEmailToClient(
      input: {
        id: $id,
        subject: $subject,
        tos: $tos,
        cc: $cc,
        bcc: $bcc,
        htmlBody: $htmlBody,
        attachments: $attachments,
        attachmentIds: $attachmentIds
      }
    ) {
      success 
    }
  }
`

export default MutationSendEmailToApplicantClient
