import React, { FC, useState, useEffect } from 'react'
import { TypographyText } from '~/core/ui/Text'
import { IAttachmentsFile } from '~/core/ui/RichEditor'
import { ISource } from '~/lib/features/candidates/types'
import File from './File'
import { useTranslation } from 'react-i18next'
import SkeletonContainer from '~/components/Skeleton'
import FileManagementLoadingView from '~/components/Settings/Profiles/Files/FileManagementLoadingView'

const FilesGroup: FC<{
  data: ISource
  onSelectedFile?: (data: ISource) => void
  filesSelected?: IAttachmentsFile[]
}> = ({ data, onSelectedFile, filesSelected }) => {
  const { label, loading, onGetData } = data
  const { t } = useTranslation()

  const [files, setFiles] = useState<IAttachmentsFile[]>([])

  const onSelectFile = (val: IAttachmentsFile) => {
    const nextFiles = files.map((file) => {
      if (file.id === val.id) {
        return val
      } else {
        return file
      }
    })

    setFiles(nextFiles)
  }

  useEffect(() => {
    const getData = async () => {
      const res = await onGetData()
      if (res.data.length) {
        const nextData = res.data.map((item) => {
          return {
            ...item,
            selected: !!filesSelected?.find(
              (file) => Number(file?.id) === Number(item?.id)
            )
          }
        })
        setFiles(nextData)
      }
    }

    getData()
  }, [])

  useEffect(() => {
    onSelectedFile?.({ ...data, attachments: files })
  }, [files])

  return (
    <div className="mb-8 last:mb-0">
      <div className="mb-4">
        <TypographyText className="text-sm font-medium text-gray-700">
          {label}
        </TypographyText>
      </div>
      <SkeletonContainer
        showMoreLabel={`${t('common:infinity:showMore')}`}
        useLoading={false}
        isFirstLoading={loading || false}
        renderCustomSkeleton={<FileManagementLoadingView />}>
        <div className="mt-3">
          <div className="space-y-2">
            {files.length ? (
              files.map((item) => {
                return (
                  <File
                    key={`file${item.id}`}
                    file={item}
                    onChecked={onSelectFile}
                  />
                )
              })
            ) : (
              <TypographyText className="text-sm text-gray-700">
                {`${t('label:no_files')}`}
              </TypographyText>
            )}
          </div>
        </div>
      </SkeletonContainer>
    </div>
  )
}

export default FilesGroup
