import configuration from '~/configuration'
import { useAgencyGraphQLRequest } from '~/core/hooks/use-agency-graphQL'
import QueryEmailTemplatesSendToClient from '../graphql/query-email-templates-send-to-client'

export function useQueryEmailTemplateSendToClient() {
  const { clientAgencyGraphQL } = useAgencyGraphQLRequest()
  const promiseEmailTemplatesSendToClient = () =>
    clientAgencyGraphQL
      .query(QueryEmailTemplatesSendToClient, {})
      .toPromise()
      .then(
        (result: {
          error: any
          data: {
            stcEmailTemplate: {
              name: string
              body: string
              subject: string
            }
          }
        }) => {
          if (result.error) {
            return {
              metadata: {
                totalCount: configuration.defaultAsyncLoadingOptions
              },
              collection: []
            }
          }

          const { stcEmailTemplate } = result.data

          return {
            metadata: { totalCount: 1 },
            collection: [stcEmailTemplate]
          }
        }
      )

  return { promiseEmailTemplatesSendToClient }
}
