import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PUBLIC_APP_URL } from '~/core/constants/env'
import { Button } from '~/core/ui/Button'
import { IFormAction } from '~/core/ui/Form'
import { IAttachmentsFile } from '~/core/ui/RichEditor'
import { Tooltip } from '~/core/ui/Tooltip'
import { catchErrorFromGraphQL } from '~/core/utilities/catch-api-error'
import { useQueryCareerSetting } from '~/lib/features/calendar/hooks/use-query-career-setting'
import MutationSendEmailToApplicantClient from '~/lib/features/candidates/graphql/mutation-send-email-to-client'
import useCandidateStore from '~/lib/features/candidates/store'
import {
  ICandidateApplicant,
  ICandidateProfile,
  ISendToClientForm
} from '~/lib/features/candidates/types'
import { useSubmitCommon } from '~/lib/hooks/use-submit-graphql-common'
import useBoundStore from '~/lib/store'
import SendToClientModal from './SendToClientModal'
import { RESUME } from '~/core/constants/enum'
import { ISelectOption } from '~/core/@types/global'
import { IconButton } from '~/core/ui/IconButton'

const SendToClientButton: FC<{
  applicantId: number
  applicantData?: ICandidateProfile
  job?: ICandidateApplicant['job']
  useIconButon?: boolean
  positionTooltip?: 'bottom' | 'top' | 'left' | 'right'
}> = ({
  applicantId,
  applicantData,
  job,
  useIconButon = false,
  positionTooltip = 'bottom'
}) => {
  const { t } = useTranslation()
  const { user, setToast, setShowLockApp, setCloseLockApp } = useBoundStore()
  const { setIsRefetchActivitiesList } = useCandidateStore()
  const [openSendModal, setOpenSendModal] = useState<boolean>(false)
  const { data: careerSetting, trigger: triggerCareerSetting } =
    useQueryCareerSetting({ variables: {}, shouldPause: true })
  const { trigger, isLoading: isSendingToClient } = useSubmitCommon(
    MutationSendEmailToApplicantClient,
    { notUpdateLoadingAfterMutation: false }
  )
  const onSubmitSendToClient = useCallback(
    (data: ISendToClientForm, formAction: IFormAction) => {
      if (isSendingToClient) return Promise.resolve()
      setShowLockApp('')
      const dataFiles = (data.files || []).reduce(
        (result, file) => {
          return {
            attachments: [
              ...result.attachments,
              ...(file.status === 'pending' && !file.id ? [file] : [])
            ],
            attachmentIds: [
              ...result.attachmentIds,
              ...(!!file.id ? [file] : [])
            ]
          }
        },
        { attachments: [], attachmentIds: [] } as {
          [key: string]: Array<IAttachmentsFile & { profileId?: number }>
        }
      )
      return trigger({
        id: Number(applicantId),
        subject: data.subject,
        htmlBody: data.htmlBody,
        tos: (data.to || []).map((item) => item.value),
        ...((data.cc || []).length > 0
          ? {
              cc: (data.cc || []).map((item) => item.value)
            }
          : {}),
        ...((data.bcc || []).length > 0
          ? {
              bcc: (data.bcc || []).map((item) => item.value)
            }
          : {}),
        ...((data.files || [])?.length > 0
          ? {
              attachments: dataFiles.attachments.map((item) => item.file),
              attachmentIds: dataFiles.attachmentIds.map((item) =>
                Number(item.id)
              )
            }
          : {})
      }).then((result) => {
        if (result.error) {
          return catchErrorFromGraphQL({
            error: result.error,
            setToast,
            formAction
          })
        }

        if (!!result.data.applicantsSendEmailToClient.success) {
          setToast({
            open: true,
            type: 'success',
            title: `${t('notification:interview:emailSent')}`
          })
          setIsRefetchActivitiesList(true)
        }
        setCloseLockApp()
        return setOpenSendModal(false)
      })
    },
    [isSendingToClient, applicantId]
  )

  useEffect(() => {
    if (!careerSetting) triggerCareerSetting()
  }, [])

  const emailList: ISelectOption[] = (
    applicantData?.company?.contacts || []
  ).map((contact) => ({
    value: contact.email,
    supportingObj: {
      name: [contact.firstName, contact.lastName]
        .filter((item) => !!item)
        .join(' '),
      description: contact.email,
      defaultColour: '#FFFFFF'
    }
  }))

  return (
    <>
      <Tooltip position={positionTooltip} content={t('button:sendToClient')}>
        {useIconButon ? (
          <IconButton
            size="xs"
            iconMenus="Send"
            type="tertiary"
            onClick={() => setOpenSendModal(true)}
          />
        ) : (
          <Button
            size="xs"
            iconMenus="Send"
            type="tertiary"
            onClick={() => setOpenSendModal(true)}
          />
        )}
      </Tooltip>
      {openSendModal && (
        <SendToClientModal
          open={openSendModal}
          onOpenChange={setOpenSendModal}
          onSubmit={onSubmitSendToClient}
          disableCTA={isSendingToClient}
          defaultValue={{
            to: emailList,
            files: (applicantData?.profileCvs?.[0]?.attachments || []).map(
              (attachment) => ({
                id: attachment.id,
                profileId: Number(applicantData?.profileCvs?.[0].id),
                url: attachment.file,
                name: attachment.blobs.filename,
                size: attachment.blobs.size,
                type: RESUME
              })
            ),
            profileId: applicantData?.id,
            optionsToSearch: emailList
          }}
          emailEditorPlaceholderValue={{
            event_preview_link:
              '<div style="margin-top: 50px; margin-bottom: 50px;"><div style="text-align: center;"><a href="%{event_preview_link}" class="button button--" target="_blank">View Event</a></div></div>',
            candidate_fullname: applicantData?.fullName || '',
            candidate_name: applicantData?.fullName || '',
            contact_values: (applicantData?.company?.contacts || [])
              .map((contact) =>
                [contact.firstName, contact.lastName]
                  .filter((item) => !!item)
                  .join(' ')
              )
              .join(', '),
            candidate_email: applicantData?.email
              ? Array.isArray(applicantData?.email)
                ? applicantData?.email?.[0]
                : applicantData?.email
              : '',
            recruiter_email: job?.owner?.email || '',
            recruiter_fullname: job?.owner?.fullName || '',
            job_title: job?.title || '',
            job_link: job?.slug
              ? `<a href="${PUBLIC_APP_URL}${careerSetting?.canonical_url}${job?.slug}">view job detail</a>`
              : '',
            company_name: user?.currentTenant?.name || '',
            career_page: `<a href="${PUBLIC_APP_URL}${careerSetting?.canonical_url}">${user?.currentTenant?.name}</a>`,
            event_full: '',
            event_attendees: '',
            event_date_time: '',
            event_location: '',
            event_type: ''
          }}
        />
      )}
    </>
  )
}

export default SendToClientButton
