import { TFunction } from 'next-i18next'
import { z } from 'zod'
import { regexEmailValidation } from '~/core/utilities/common'

const schemaSendToClient = (t: TFunction) => {
  return z.object({
    subject: z
      .string({
        required_error: `${t('form:subject_required')}`
      })
      .trim()
      .min(1, {
        message: `${t('form:subject_required')}`
      }),
    htmlBody: z
      .string({
        required_error: `${t('form:content_required')}`
      })
      .trim()
      .min(1, {
        message: `${t('form:content_required')}`
      }),
    to: z
      .array(
        z.object({
          value: z.string().trim()
        })
      )
      .min(1, {
        message: `${t('form:to_required')}`
      })
      .refine(
        (toArr) => {
          return (
            !toArr ||
            toArr.length === 0 ||
            !toArr.find((to) => !regexEmailValidation.test(to.value))
          )
        },
        {
          message: `${t('form:invalid_email')}`
        }
      ),
    cc: z
      .array(
        z.object({
          value: z.string().trim()
        })
      )
      .optional()
      .refine(
        (ccArr) => {
          return (
            !ccArr ||
            ccArr.length === 0 ||
            !ccArr.find((cc) => !regexEmailValidation.test(cc.value))
          )
        },
        {
          message: `${t('form:invalid_email')}`
        }
      ),
    bcc: z
      .array(
        z.object({
          value: z.string().trim()
        })
      )
      .optional()
      .refine(
        (bccArr) => {
          return (
            !bccArr ||
            bccArr.length === 0 ||
            !bccArr.find((bcc) => !regexEmailValidation.test(bcc.value))
          )
        },
        {
          message: `${t('form:invalid_email')}`
        }
      )
  })
}

export default schemaSendToClient
