import React, { FC } from 'react'
import { Checkbox } from '~/core/ui/Checkbox'
import { TypographyText } from '~/core/ui/Text'
import IconWrapper from '~/core/ui/IconWrapper'
import { Tooltip } from '~/core/ui/Tooltip'
import { IAttachmentsFile } from '~/core/ui/RichEditor'
import { Controller, useForm } from 'react-hook-form'

const File: FC<{
  file: IAttachmentsFile
  onChecked: (val: IAttachmentsFile) => void
}> = ({ file, onChecked }) => {
  const { control } = useForm()

  return (
    <div className="mb-4 flex h-[36px] items-center justify-between border-b-[1px] border-solid border-b-gray-100 last:mb-0">
      <div className="flex items-center px-3">
        <Controller
          control={control}
          name="file"
          render={() => (
            <Checkbox
              size="sm"
              className="m-0"
              isChecked={file.selected}
              onCheckedChange={(e) => {
                const checked = e.target.checked
                onChecked({ ...file, selected: checked })
              }}
            />
          )}
        />

        <div className="pl-3">
          <IconWrapper
            size={14}
            name="Paperclip"
            className="text-gray-600 dark:text-gray-400"
          />
        </div>
        <div className="ml-3">
          <Tooltip content={file.name}>
            <TypographyText className="max-w-[450px] truncate text-base text-gray-700">
              {file.name}
            </TypographyText>
          </Tooltip>
        </div>
      </div>
    </div>
  )
}

export default File
