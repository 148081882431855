import { FC, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { Avatar } from '~/core/ui/Avatar'
import { Button } from '~/core/ui/Button'
import { Dialog } from '~/core/ui/Dialog'
import { Divider } from '~/core/ui/Divider'
import IconWrapper, { LucideIconName } from '~/core/ui/IconWrapper'
import { TypographyText } from '~/core/ui/Text'
import { Tooltip } from '~/core/ui/Tooltip'
import { defaultFormatDate } from '~/core/utilities/format-date'
import { IPlacement } from '~/features/placements/placement'
import { ICandidateApplicant } from '~/lib/features/candidates/types'
import { formatMoneyWithComma } from '~/lib/features/placements/utilities'
const RowInfoWrapper: FC<{
  hide?: boolean
  nameIcon: LucideIconName
  labelName: string
  content: string | ReactNode
  classNameLeftView?: string
}> = ({ nameIcon, labelName, content, classNameLeftView, hide = false }) => {
  return !hide ? (
    <div className="mt-3 grid w-full grid-cols-1 gap-[2px] tablet:grid-cols-[132px_1fr] tablet:gap-4">
      <div
        className={`flex flex-row items-center self-start ${
          classNameLeftView || ''
        }`}>
        <IconWrapper size={16} className="text-gray-600" name={nameIcon} />
        <TypographyText className="ml-2 text-sm font-normal text-gray-700">
          {labelName}
        </TypographyText>
      </div>
      <div className="min-w-0 flex-1 text-sm text-gray-900 tablet:w-full">
        {content}
      </div>
    </div>
  ) : (
    <></>
  )
}
const MarkAsHiredSuccessModal: FC<{
  open: boolean
  setOpen: (open: boolean) => void
  applicantMarkAsHired?: ICandidateApplicant
  placement?: IPlacement
  setApplicantMarkAsHired?: (value?: ICandidateApplicant) => void
}> = ({
  open,
  setOpen,
  applicantMarkAsHired,
  setApplicantMarkAsHired,
  placement
}) => {
  const { t } = useTranslation()
  return (
    <Dialog
      className="min-w-[480px]"
      open={open}
      size="sm"
      childrenWrapperClassName="tablet:px-0 pb-0 px-0 tablet:pb-0"
      onOpenChange={setOpen}
      isHeading={false}
      isPreventAutoFocusDialog={true}>
      <>
        <div className="mb-1 bg-[url('/img/feature/jobBoards/bg-success-hired.png')] bg-contain bg-no-repeat px-[58px] pt-20 text-center">
          <div className="text-lg font-semibold text-gray-900">
            {t('job:detail:changeStage:congratsOnHiring')}
          </div>
          <div className="text-sm text-gray-900">
            {`${t('job:detail:changeStage:descriptionCongratsOnHiring')} `}
            <div className="font-medium text-gray-900">
              {applicantMarkAsHired?.job?.title}
            </div>
          </div>
        </div>
        <div className="px-6">
          <Divider className="my-4" />
        </div>
        <div className="px-6">
          {placement ? (
            <>
              <RowInfoWrapper
                hide={!placement?.company?.name}
                nameIcon="Building"
                labelName={t('job:detail:changeStage:company')}
                content={
                  <div className="flex">
                    <Tooltip content={placement?.company?.name}>
                      <div className="line-clamp-1 break-all text-sm font-medium text-gray-900">
                        {placement?.company?.name}
                      </div>
                    </Tooltip>
                  </div>
                }
              />
              <RowInfoWrapper
                hide={!placement?.hiredDate}
                nameIcon="CalendarCheck2"
                labelName={t('job:detail:changeStage:hiredDate')}
                content={
                  <div className="text-sm text-gray-900">
                    {placement?.hiredDate &&
                      defaultFormatDate(new Date(placement?.hiredDate))}
                  </div>
                }
              />
              <RowInfoWrapper
                hide={!placement?.onboardDate && !placement?.endOfProbationDate}
                nameIcon="Calendar"
                labelName={t('job:detail:changeStage:startEndDate')}
                content={
                  <div className="text-sm text-gray-900">
                    {placement?.onboardDate &&
                      defaultFormatDate(new Date(placement?.onboardDate))}
                    {placement?.endOfProbationDate &&
                      ` - ${defaultFormatDate(
                        new Date(placement?.endOfProbationDate)
                      )}`}
                  </div>
                }
              />
              <RowInfoWrapper
                hide={!placement?.salary}
                nameIcon="DollarSign"
                labelName={t('job:detail:changeStage:salary')}
                content={
                  <div className="break-all text-sm text-gray-900">
                    {formatMoneyWithComma(Number(placement?.salary))}{' '}
                    {placement?.currencyOfSalary}{' '}
                    <span className="text-sm  text-gray-600">
                      ({placement?.typeOfSalary})
                    </span>
                  </div>
                }
              />
              <RowInfoWrapper
                hide={!placement?.fee}
                nameIcon="Scroll"
                labelName={t('job:detail:changeStage:fee')}
                content={
                  <div className="break-all text-sm text-gray-900">
                    {formatMoneyWithComma(Number(placement?.fee))}{' '}
                    {{ percentage: '%', months: 'month(s)' }[
                      placement?.typeOfFee as string
                    ] ||
                      placement?.currencyOfRevenue ||
                      ''}
                  </div>
                }
              />
              <RowInfoWrapper
                hide={!placement?.revenue}
                nameIcon="DollarSign"
                labelName={t('job:detail:changeStage:revenue')}
                content={
                  <div className="break-all text-sm text-gray-900">
                    {formatMoneyWithComma(Number(placement?.revenue))}{' '}
                    {placement?.currencyOfRevenue}
                  </div>
                }
              />
              <RowInfoWrapper
                hide={!!(placement?.profitSplits?.length == 0)}
                nameIcon="Users"
                labelName={t('job:detail:changeStage:profitSplit')}
                content={placement?.profitSplits?.map((split, index) => (
                  <div key={index} className="mb-2">
                    <div className="flex items-center">
                      <Avatar
                        size="xs"
                        color={split?.user?.defaultColour}
                        src={split?.user?.avatarVariants?.thumb?.url}
                        alt={split?.user?.fullName}
                      />
                      <Tooltip content={split?.user?.fullName}>
                        <div className="ml-2 line-clamp-1 text-sm font-medium text-gray-900">
                          {split?.user?.fullName}
                        </div>
                      </Tooltip>
                      <div className="ml-2 h-1 w-1 animate-bounce rounded-full bg-gray-400"></div>
                      <div className="ml-2 text-sm  font-medium  text-gray-900">
                        {split?.profitPercentage}%
                      </div>
                    </div>
                  </div>
                ))}
              />
            </>
          ) : (
            <>
              <RowInfoWrapper
                hide={!applicantMarkAsHired?.profile?.sourcedDescription}
                nameIcon="Search"
                labelName={t('job:detail:changeStage:source')}
                content={
                  <div className="text-sm text-gray-900">
                    {applicantMarkAsHired?.profile?.sourcedDescription}
                  </div>
                }
              />
              {applicantMarkAsHired?.referral && (
                <RowInfoWrapper
                  hide={!applicantMarkAsHired?.referral}
                  nameIcon="UserPlus"
                  labelName={t('job:detail:changeStage:referredBy')}
                  content={
                    <div className="flex">
                      <Avatar
                        size="xs"
                        color={
                          applicantMarkAsHired?.referral?.user.defaultColour
                        }
                        src={
                          applicantMarkAsHired?.referral?.user.avatarVariants
                            ?.thumb?.url
                        }
                        alt={applicantMarkAsHired?.referral?.user.fullName}
                      />
                      <Tooltip
                        content={applicantMarkAsHired?.referral?.user.fullName}>
                        <div className="ml-2 line-clamp-1 text-sm text-gray-900">
                          {applicantMarkAsHired?.referral?.user.fullName}
                        </div>
                      </Tooltip>
                    </div>
                  }
                />
              )}
              <RowInfoWrapper
                nameIcon="UserCheck"
                labelName={t('job:detail:changeStage:hiredBy')}
                content={
                  <div className="flex">
                    <Avatar
                      size="xs"
                      color={applicantMarkAsHired?.hiredBy?.defaultColour}
                      src={
                        applicantMarkAsHired?.hiredBy?.avatarVariants?.thumb
                          ?.url
                      }
                      alt={applicantMarkAsHired?.hiredBy?.fullName}
                    />
                    <Tooltip content={applicantMarkAsHired?.hiredBy?.fullName}>
                      <div className="ml-2 line-clamp-1 text-sm text-gray-900">
                        {applicantMarkAsHired?.hiredBy?.fullName}
                      </div>
                    </Tooltip>
                  </div>
                }
              />

              <RowInfoWrapper
                nameIcon="Calendar"
                labelName={t('job:detail:changeStage:timeToHire')}
                content={
                  <div className="text-sm text-gray-900">
                    {applicantMarkAsHired?.timeToHire} day(s)
                  </div>
                }
              />
            </>
          )}
        </div>
        <div className="flex justify-end pb-6 pr-6 pt-6">
          <Button
            label={`${t('button:done')}`}
            type="primary"
            size="sm"
            onClick={() => {
              setApplicantMarkAsHired && setApplicantMarkAsHired()
              setOpen(false)
            }}
          />
        </div>
      </>
    </Dialog>
  )
}

export default MarkAsHiredSuccessModal
